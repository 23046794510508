import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Helmet } from 'react-helmet'

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'

import Navigation from './components/Navigation';

import MainContent from './components/MainContent';
import Marcacoes from './components/Marcacoes';
import Footer from './components/Footer';
import Splash from './components/Splash';
import Marcas from './components/Marcas';
import Protocolos from './components/Protocolos';
import Sobre from './components/Sobre';
import ScrollToTop from "./components/ScrollToTop";
import CampanhaLentes from "./components/CampanhaLentes"
import NewsletterSignUp from './components/NewsletterSignUp';


library.add(fas, far, fab)

function App() {
  return (
    <Router>
      <div className="App">
        {/* <Navigation /> */}

        {/* <Logo resolution="400" stroke="2"/> */}
        <Helmet>
          <html lang="en" />
          <title>Ótica Íris - O futuro da sua visão começa aqui.</title>
          <meta
            name="description"
            content="Ótica Íris - O futuro da sua visão começa aqui."
          />
        </Helmet>

        <Routes>

          <Route path="/marcacoes" element={[<ScrollToTop />, <Navigation />, <Marcacoes />, <Footer />]} />
          <Route path="/marcas" element={[<ScrollToTop />, <Navigation />, <Marcas />, <Footer />]} />
          <Route path="/protocolos" element={[<ScrollToTop />, <Navigation />, <Protocolos />, <Footer />]} />
          <Route path="/parceiros" element={[<ScrollToTop />, <Navigation />, <Protocolos />, <Footer />]} />
          <Route path="/sobre" element={[<ScrollToTop />, <Navigation />, <Sobre />, <Footer />]} />
          <Route path="/campanhas" element={[<CampanhaLentes />]} />
          <Route path="/news-preview" element={[<NewsletterSignUp segment="test"/>]} />
          {/* <Route path="/" element={<>
            <Splash />
          </>} /> */}
          <Route path="/" element={[<ScrollToTop />, <Navigation />, <MainContent />, <Footer />]} />
        </Routes>

      </div >
    </Router>
  );
}

export default App;
