import React from "react";
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import styles from './modules/Marcacoes.module.css';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Card from '@mui/material/Card';
// import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import moment from "moment";


class MarcacoesConfirm extends React.Component {

   post = e => {
      this.continue(e);
      const requestOptions = {
         method: 'POST',
         headers: { 
            'Content-type': 'application/json; charset=UTF-8',
         },
         mode: "cors",
         body: JSON.stringify({ 
            nome: this.props.values.nome, 
            telefone: this.props.values.telefone, 
            tipo: this.props.values.tipo,
            comentario: this.props.values.comentario,
            datahora: moment(this.props.values.datahora).format("YYYY-MM-DD HH:mm:ss")
            // (this.props.values.datahora.getFullYear() + "-" + (this.props.values.datahora.getMonth() < 9 ? "0" + this.props.values.datahora.getMonth()+1 : this.props.values.datahora.getMonth()+1) + "-" + (this.props.values.datahora.getDate() < 10 ? "0" + this.props.values.datahora.getDate() : this.props.values.datahora.getDate()) + " " + this.props.values.datahora.getTime())
          })
      };
      fetch('/api/marcacao/', requestOptions)
         .then(response => response.json())
         .then(data => {
            console.log('Response: ' + data.Id)
            this.continue(e);
         })
         .catch(error => {
            console.error('There was an error! --- ', error);
        });
   }

   continue = e => {
      e.preventDefault();
      this.props.nextStep();
   };

   back = e => {
      e.preventDefault();
      this.props.prevStep();
   };
   renderSwitch(param) {
      switch (param) {
         case '1':
            return (
               <div className={styles.itemSecondary}>
                  <div className={styles.icon_wrapper}>
                     <FontAwesomeIcon icon="fa-solid fa-eye" className={styles.iconSecondary} />
                  </div>
                  <div className={styles.text_wrapper}>
                     <h3>Consulta de Optometria</h3>
                     <div className={styles.item_detail}>30 min - Consulta Visual. Realizamos os exames necessários para a deteção de erros refrativos e fazemos a sua respetiva correção. Avaliamos também a sua saúde ocular</div>
                  </div>
               </div>
            );
         case '2':
            return (
               <div className={styles.itemSecondary}>
                  <div className={styles.icon_wrapper}>
                     <FontAwesomeIcon icon="fa-regular fa-eye" className={styles.iconSecondary} />
                  </div>
                  <div className={styles.text_wrapper}>
                     <h3>Quero experimentar lentes de contacto</h3>
                     <div className={styles.item_detail}>30 min - Consulta visual com foco na utilização de lentes de contacto e 1ª experimentação</div>
                  </div>
               </div>
            )
         case '3':
            return (
               <div className={styles.itemSecondary}>
                  <div className={styles.icon_wrapper}>
                     <FontAwesomeIcon icon="fa-solid fa-id-card" className={styles.iconSecondary} />
                  </div>
                  <div className={styles.text_wrapper}>
                     <h3>Preciso de renovar a carta de condução</h3>
                     <div className={styles.item_detail}>30 min - Realização dos exames necessários para renovação da sua carta de condução</div>
                  </div>
               </div>
            )
         default:
            return (
               <div className={styles.itemSecondary}>
                  <div className={styles.icon_wrapper}>
                     <FontAwesomeIcon icon="fa-solid fa-eye" className={styles.iconSecondary} />
                  </div>
                  <div className={styles.text_wrapper}>
                     <h3>Consulta de Optometria</h3>
                     <div className={styles.item_detail}>30 min - Consulta Visual</div>
                  </div>
               </div>
            );
      }
   }
   renderComments(text) {
      if (text.length > 0) {
         return (
            <ListItem>
               <ListItemIcon>
                  <FontAwesomeIcon icon="fa-solid fa-message" />
               </ListItemIcon>
               <ListItemText primary={text} />
            </ListItem>
         )
      }
   }

   render() {
      const { values, handleChange, updateDateTime } = this.props;
      return (
         <div className={styles.main}>
            <div className={styles.content}>
               <h1 className={styles.extraMargin}>Confirma os dados da tua consulta</h1>

               <div className={styles.sub_content}>
                  {/* <h3>Os teus dados</h3> */}
                  {/* <p><FontAwesomeIcon icon="fa-regular fa-user" className={styles.icon} /> {values.nome}</p>
                  <p><FontAwesomeIcon icon="fa-solid fa-mobile" className={styles.icon} /> {values.telefone}</p> */}
                  <Card>
                     <div className={styles.typeOfInverted}>
                        {this.renderSwitch(values.tipo)}
                     </div>
                     <CardContent>
                        <List>
                           <ListItem>
                              <ListItemIcon>
                                 <FontAwesomeIcon icon="fa-regular fa-user" />
                              </ListItemIcon>
                              <ListItemText primary={values.nome} />
                           </ListItem>
                           <ListItem>
                              <ListItemIcon>
                                 <FontAwesomeIcon icon="fa-solid fa-mobile" />
                              </ListItemIcon>
                              <ListItemText primary={values.telefone} />
                           </ListItem>
                           <ListItem>
                              <ListItemIcon>
                                 <FontAwesomeIcon icon="fa-regular fa-calendar" />
                              </ListItemIcon>
                              <ListItemText primary={moment(values.datahora).format("dddd, MMMM Do YYYY, k:mm")} />
                           </ListItem>
                           {this.renderComments(values.comentario)}
                        </List>
                     </CardContent>
                  </Card>
               </div>
            </div>
            <div className={styles.actionButtons}>
               <Button variant="outlined" startIcon={<FontAwesomeIcon icon="fa-solid fa-arrow-left" />} onClick={this.back}>Voltar atrás</Button>&nbsp;
               <Button variant="contained" endIcon={<FontAwesomeIcon icon="fa-regular fa-calendar" />} onClick={this.post} className={'button_next_3'}>Fazer Marcação</Button>
            </div>
            <div className={styles.footNote}>
               Ao realizar a sua marcação através do nosso site, está a dar autorização à Ótica Íris para o contactar através do telefone no seguimento desta consulta.
            </div>
         </div>
      );
   }
}
export default MarcacoesConfirm;