import React from "react";
import styles from './modules/Footer.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom';


class Footer extends React.Component {
    render() {
        const { style } = this.props;
        return (
            // <div className={styles.itemStyle}></div>
            <footer className={style}>
                <div className={styles.container}>
                    <div className={styles.container_left}>
                        {/* <div className={styles.item}>
                            <p className={styles.title}>Ótica Íris</p>
                            O futuro da sua visão começa aqui.

                        </div> */}
                        <div className={styles.item}>
                            <div className={styles.item_contact}>
                                <p className={styles.title}><FontAwesomeIcon icon="far-regular fa-phone" className={styles.icon} /> Contactos</p>
                            </div>
                            <div className={styles.item_contact}>
                                (+351) 253 173 501 *
                                <p className={styles.footnote}>* Chamada para a rede fixa nacional</p>
                            </div>
                            <div className={styles.item_contact}>
                                {/* <FontAwesomeIcon icon="fas-solid fa-mobile-screen" className={styles.icon} />  */}
                                (+351) 933 357 399 **
                                <p className={styles.footnote}>** Chamada para a rede móvel nacional</p>
                            </div>
                        </div>
                        <div className={styles.item}>
                            <div className={styles.item_contact}>
                                <p className={styles.title}><FontAwesomeIcon icon="fas-regular fa-location-dot" className={styles.icon_2} />Loja</p>
                            </div>
                            <div className={styles.item_address}>
                                <div className={styles.address_detail}>Avenida Dom João II 108, 4715-304 Nogueiró - Braga</div>
                            </div>
                            <div className={styles.item_livro}>
                            <div className={styles.livro_detail}>
                            <FontAwesomeIcon icon="fa-solid fa-book" className={styles.icon_2} /><a href="https://www.livroreclamacoes.pt/Inicio/" target="_blank">Livro de Elogios e Reclamações</a></div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.container_right}>
                        <div className={styles.item}>
                            <div className={styles.item_contact}>
                                <p className={styles.title}><FontAwesomeIcon icon="fa-solid fa-thumbs-up" className={styles.icon_2} />Redes Sociais</p>
                            </div>
                            <div className={styles.item_social}>
                                <FontAwesomeIcon icon="fa-brands fa-facebook-square" className={styles.icon} /> <a href="https://www.facebook.com/oticairisbragaa" target="_blank">oticairisbragaa</a>
                            </div>
                            <div className={styles.item_social}>
                                <FontAwesomeIcon icon="fa-brands fa-instagram" className={styles.icon} /> <a href="https://www.instagram.com/oticairisbraga" target="_blank">oticairisbraga</a>
                            </div>
                            
                            
                        </div>
                        <div className={styles.item}>
                            <div className={styles.item_contact}>
                                <p className={styles.title}><FontAwesomeIcon icon="fa-solid fa-clock" className={styles.icon} /> Horário de Abertura</p>
                            </div>
                            <div className={styles.item_social}>
                            <table>
                                <tbody>
                                    <tr><th>Seg-Sex:</th><td><span>9:30 – 19:00</span></td></tr>
                                    <tr><th>Sab:</th><td><span>9:30 – 13:00</span></td></tr>
                                    <tr><th>Dom:</th><td><span>Fechado</span></td></tr>
                                </tbody></table>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.copyright}>oticairis.pt © 2024 - desenvolvido por <a href="https://dividebyzero.sh/" target="_blank" id="dividebyzero">dividebyzero</a></div>
            {/* | <a href="https://www.livroreclamacoes.pt/Inicio/" target="_blank">Livro de Elogios e Reclamações</a> */}
            </footer >
        );
    }
}
export default Footer;