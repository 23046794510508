import React from "react";
import styles from './modules/Marcacoes.module.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


class MarcacoesStepOne extends React.Component {
   continue = input => e => {
      e.preventDefault();
      this.props.updateTipo(input);
      this.props.nextStep();
   };
   render() {
      const { values, handleChange, updateTipo } = this.props;
      return (
         // <div className={styles.itemStyle}></div>
         <div className={styles.main}>
            <div className={styles.content}>

               <h1>Agende a sua consulta</h1>
               <div class={styles.spaceFiller}></div>
               <div className={styles.typeOf}>
                  <div className={styles.item} onClick={this.continue('1')}>
                     <div class={styles.itemWrapper}>
                        <div className={styles.icon_wrapper}>
                           <FontAwesomeIcon icon="fa-solid fa-eye" className={styles.icon} />
                        </div>
                        <div className={styles.text_wrapper}>
                           <h3>Consulta de Optometria</h3>
                           <div className={styles.item_detail}>30 min - Consulta Visual. Realizamos os exames necessários para a deteção de erros refrativos e fazemos a sua respetiva correção. Avaliamos também a sua saúde ocular</div>
                        </div>
                     </div>
                  </div>
                  <div className={styles.item} onClick={this.continue('2')}>
                     <div class={styles.itemWrapper}>
                        <div className={styles.icon_wrapper}>
                           <FontAwesomeIcon icon="fa-regular fa-eye" className={styles.icon} />
                        </div>
                        <div className={styles.text_wrapper}>
                           <h3>Quero experimentar lentes de contacto</h3>
                           <div className={styles.item_detail}>30 min - Consulta visual com foco na utilização de lentes de contacto e 1ª experimentação</div>
                        </div>
                     </div>
                  </div>
                  <div className={styles.item} onClick={this.continue('3')}>
                     <div class={styles.itemWrapper}>
                        <div className={styles.icon_wrapper}>
                           <FontAwesomeIcon icon="fa-solid fa-id-card" className={styles.icon} />
                        </div>
                        <div className={styles.text_wrapper}>
                           <h3>Preciso de renovar a carta de condução</h3>
                           <div className={styles.item_detail}>30 min - Realização dos exames necessários para renovação da sua carta de condução</div>
                        </div>
                     </div>
                  </div>
               </div>
               
               {/* <form onSubmit={this.handleSubmit}>
                  <div>
                     <label for="nome">Nome:</label>
                     <input name="nome" required />
                  </div>
                  <div>
                     <label for="telefone">Enter your phone number:</label>
                     <input name="telefone" required />
                  </div>
                  <div>
                     <select name="tipo">
                        <option value="optometria">Consulta Optometria</option>
                        <option value="lentes">Quero experimentar lentes de contacto</option>
                        <option value="carta">Preciso de renovar a carta de condução</option>
                     </select>
                  </div>


                  <textarea name="comentario" />
                  <button type="submit">Submit</button>
               </form> */}
            </div>
         </div>
      );
   }
}
export default MarcacoesStepOne;