import React from "react";
import styles from './modules/Sobre.module.css';
import Protocolos from "./Protocolos";
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
// import hero from '../assets/hero_desktop.png'
// import hero_xs from '../assets/hero_mobile_2.png'
import Box from "@mui/material/Box";

class Sobre extends React.Component {
    render() {
        return (
            // <div className={styles.itemStyle}></div>
            <div className={styles.main}>
                {/* <section className={styles.sobre_section}>
                    <Box component="img" src={hero} className={styles.main_image} sx={{ display: { xs: 'none', md: 'block' } }} />
                    <Box component="img" src={hero_xs} className={styles.main_image} sx={{ display: { xs: 'block', md: 'none' } }} />
                </section> */}

                <section className={styles.sobre_section}>
                    <div className={styles.about_wide}>
                        <h2 className={styles.titulo}>Equipa</h2>
                        Apresentamos a equipa da Ótica Íris: as optometristas Cátia e Cláudia são as responsáveis por cuidar da visão de todos aqueles que procuram a nossa loja.
                        Ambas licenciadas na Universidade do Minho e pertencentes à Associação dos Profissionais Licenciados de Optometria (APLO), estas duas jovens dispõem de todos os conhecimentos necessários para prestarem um serviço de excelência.
                        Se pretende que a sua visão tenha a atenção que merece e um aconselhamento especializado, a Ótica Íris é o lugar certo!
                    </div>
                </section>
                <section className={styles.sobre_section}>
                    <div className={styles.team}>
                        {/* sx={{ display: { xs: 'block', md: 'none' } }} */}
                        <ImageList variant="quilted" cols={1} gap={15} className={styles.imgGrid}>
                            <ImageListItem key="1">
                                <img
                                    src={require('../assets/equipa_otica_iris.jpg')}
                                    srcSet={require('../assets/equipa_otica_iris.jpg')}
                                    alt="Equipa Ótica Íris, Cátia Esteves à esquerda e Cláudia Gomes à direita."
                                    loading="lazy"
                                />
                                <ImageListItemBar
                                    // title={item.title}
                                    subtitle="Equipa Ótica Íris, Cátia Esteves à esquerda e Cláudia Gomes à direita."
                                />
                            </ImageListItem>
                        </ImageList>
                    </div>
                </section>

                <section className={styles.sobre_section}>
                    <div className={styles.wrapper}>
                        <div className={styles.about}>
                            <h2 className={styles.titulo}>A nossa Loja</h2>
                            Situada numa das principais avenidas de Nogueiró, a
                            Ótica Íris nasceu do sonho de duas
                            optometristas, que decidiram embarcar nesta
                            aventura de poderem proporcionar um serviço de
                            excelência no mundo dos cuidados de saúde visual
                            primários.
                        </div>
                        <div className={styles.about}>
                            <h2 className={styles.titulo}>O que nos distingue</h2>
                            O que nos distingue? Acreditamos que cada cliente
                            é único, daí a necessidade de tentarmos sempre
                            encontrar a solução que melhor se adequa a si. Isto
                            aliado ao profissionalismo e ao know-how da nossa
                            equipa leva a que a nossa loja seja o sítio perfeito
                            para cuidar da sua saúde ocular. Afinal, o futuro da
                            sua visão começa aqui!
                        </div>
                    </div>
                </section>

                <section className={styles.sobre_section}>
                    <div className={styles.gallery}>
                        {/* sx={{ display: { xs: 'block', md: 'none' } }} */}
                        <ImageList variant="quilted" cols={3} gap={15} className={styles.imgGrid}>
                            <ImageListItem key="1">
                                <img
                                    src={require('../assets/store/IMG_4546.jpg')}
                                    srcSet={require('../assets/store/IMG_4546.jpg')}
                                    loading="lazy"
                                />
                            </ImageListItem>
                            <ImageListItem key="2">
                                <img
                                    src={require('../assets/store/IMG_4559.jpg')}
                                    srcSet={require('../assets/store/IMG_4559.jpg')}
                                    // alt="image title"
                                    loading="lazy"
                                />
                            </ImageListItem>
                            <ImageListItem key="3">
                                <img
                                    src={require('../assets/store/IMG_4628.jpg')}
                                    srcSet={require('../assets/store/IMG_4628.jpg')}
                                    // alt="image title"
                                    loading="lazy"
                                />
                            </ImageListItem>
                            <ImageListItem key="4">
                                <img
                                    src={require('../assets/store/IMG_4636.jpg')}
                                    srcSet={require('../assets/store/IMG_4636.jpg')}
                                    // alt="image title"
                                    loading="lazy"
                                />
                            </ImageListItem>
                            <ImageListItem key="5">
                                <img
                                    src={require('../assets/store/IMG_4685.jpg')}
                                    srcSet={require('../assets/store/IMG_4685.jpg')}
                                    // alt="image title"
                                    loading="lazy"
                                />
                            </ImageListItem>
                            <ImageListItem key="6">
                                <img
                                    src={require('../assets/store/IMG_4919.png')}
                                    srcSet={require('../assets/store/IMG_4919.png')}
                                    // alt="image title"
                                    loading="lazy"
                                />
                            </ImageListItem>
                        </ImageList>
                    </div>
                </section>
                {/* <Protocolos /> */}
            </div>
        );
    }
}
export default Sobre;