import React from "react";
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import styles from './modules/Marcacoes.module.css';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Card from '@mui/material/Card';
// import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import moment from "moment";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

class MarcacoesSuccess extends React.Component {

   renderSwitch(param) {
      switch (param) {
         case '1':
            return (
               <div className={styles.itemSecondary}>
                  <div className={styles.icon_wrapper}>
                     <FontAwesomeIcon icon="fa-solid fa-eye" className={styles.iconSecondary} />
                  </div>
                  <div className={styles.text_wrapper}>
                     <h3>Consulta de Optometria</h3>
                     <div className={styles.item_detail}>30 min - Consulta Visual. Realizamos os exames necessários para a deteção de erros refrativos e fazemos a sua respetiva correção. Avaliamos também a sua saúde ocular</div>
                  </div>
               </div>
            );
         case '2':
            return (
               <div className={styles.itemSecondary}>
                  <div className={styles.icon_wrapper}>
                     <FontAwesomeIcon icon="fa-regular fa-eye" className={styles.iconSecondary} />
                  </div>
                  <div className={styles.text_wrapper}>
                     <h3>Quero experimentar lentes de contacto</h3>
                     <div className={styles.item_detail}>30 min - Consulta visual com foco na utilização de lentes de contacto e 1ª experimentação</div>
                  </div>
               </div>
            )
         case '3':
            return (
               <div className={styles.itemSecondary}>
                  <div className={styles.icon_wrapper}>
                     <FontAwesomeIcon icon="fa-solid fa-id-card" className={styles.iconSecondary} />
                  </div>
                  <div className={styles.text_wrapper}>
                     <h3>Preciso de renovar a carta de condução</h3>
                     <div className={styles.item_detail}>30 min - Realização dos exames necessários para renovação da sua carta de condução</div>
                  </div>
               </div>
            )
         default:
            return (
               <div className={styles.itemSecondary}>
                  <div className={styles.icon_wrapper}>
                     <FontAwesomeIcon icon="fa-solid fa-eye" className={styles.iconSecondary} />
                  </div>
                  <div className={styles.text_wrapper}>
                     <h3>Consulta de Optometria</h3>
                     <div className={styles.item_detail}>30 min - Consulta Visual</div>
                  </div>
               </div>
            );
      }
   }
   renderComments(text) {
      if (text.length > 0) {
         return (
            <ListItem>
               <ListItemIcon>
                  <FontAwesomeIcon icon="fa-solid fa-message" />
               </ListItemIcon>
               <ListItemText primary={text} />
            </ListItem>
         )
      }
   }

   render() {
      const { values, handleChange, updateDateTime } = this.props;
      return (
         <div className={styles.main}>
            <div className={styles.content}>
               <h1 className={styles.extraMargin}>Confirma os dados da tua consulta</h1>

               <div className={styles.sub_content}>
                  {/* <h3>Os teus dados</h3> */}
                  {/* <p><FontAwesomeIcon icon="fa-regular fa-user" className={styles.icon} /> {values.nome}</p>
                  <p><FontAwesomeIcon icon="fa-solid fa-mobile" className={styles.icon} /> {values.telefone}</p> */}
                  <Card>
                     <div className={styles.typeOfInverted}>
                        {this.renderSwitch(values.tipo)}
                     </div>
                     <CardContent>
                        <List>
                           <ListItem>
                              <ListItemIcon>
                                 <FontAwesomeIcon icon="fa-regular fa-user" />
                              </ListItemIcon>
                              <ListItemText primary={values.nome} />
                           </ListItem>
                           <ListItem>
                              <ListItemIcon>
                                 <FontAwesomeIcon icon="fa-solid fa-mobile" />
                              </ListItemIcon>
                              <ListItemText primary={values.telefone} />
                           </ListItem>
                           <ListItem>
                              <ListItemIcon>
                                 <FontAwesomeIcon icon="fa-regular fa-calendar" />
                              </ListItemIcon>
                              <ListItemText primary={moment(values.datahora).format("dddd, MMMM Do YYYY, k:mm")} />
                           </ListItem>
                           {this.renderComments(values.comentario)}
                        </List>
                     </CardContent>
                  </Card>
               </div>
            </div>
            <div className={styles.successMessage}>
               <Alert severity="success">
                  <AlertTitle>Marcação feita!</AlertTitle>
                  A nossa equipa fica à tua espera. Até breve!
               </Alert>
            </div>
         </div>
      );
   }
}
export default MarcacoesSuccess;