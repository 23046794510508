import React, { useEffect, useState } from "react";
import {Helmet} from "react-helmet";
import styles from './modules/CampanhaLentes.module.css';
import ScrollToTop from "./ScrollToTop";
import Navigation from "./Navigation";
import Footer from "./Footer"
import Logo from './Logo-dark';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const CampanhaLentes = (props) => {

    const [nome, setNome] = useState('')
    const [telefone, setTelefone] = useState('')
    const [data, setData] = useState('')
    const [email, setEmail] = useState('')
    const [consent, setConsent] = useState(false)
    const [isValid, setValidForm] = useState(false)
    const [complete, setComplete] = useState(false)
    // let isValid = false;
    const isFormValid = () => {
        // console.log("consent is: " + ((new Date(data)) <= new Date('2002-11-01')))
        if (nome.length > 5 && telefone.length > 8 && data != '' &&
            (new Date(data)) <= new Date('2002-11-01') &&
            (new Date(data)) >= new Date('1922-01-01') &&
            email.length > 10 && consent) {
            // setValidForm({ valid: true });
            // console.log("form is valid " + new Date(data));
            setValidForm(true);
        }
        else {
            // setValidForm({ valid: false });
            // console.log("form is not valid");
            setValidForm(false)
        }
    };
    const handleChange = (e, input) => {
        // console.log("change: " + e.target.checked);
        switch (input) {
            case 'nome': setNome(e.target.value); break;
            case 'telefone': setTelefone(e.target.value); break;
            case 'email': setEmail(e.target.value); break;
            case 'dataNascimento': setData(e.target.value); break;
            case 'consent': setConsent(e.target.checked); break;
            default: return;
        }

    };
    const post = e => {

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
            mode: "cors",
            body: JSON.stringify({
                nome: nome,
                telefone: telefone,
                email: email,
                campo_extra1: data,
                consent: consent,
                campanha: "1"
            })
        };
        fetch('/api/campanha/', requestOptions)
            .then(response => response.json())
            .then(data => {
                // console.log('Response: ' + data.Id)
                this.formSubmitted(e);
            })
            .catch(error => {
                // console.error('There was an error! --- ', error);
            });

        setComplete(true)

    }
    const formSubmitted = e => {
        return null;
    }
    useEffect(() => {
        isFormValid();
    });
    const renderForm = () => {
        return (
            <div>
                
                <Helmet>
                <meta name="description" content="Experimenta grátis as tuas lentes de contacto"></meta>
                </Helmet>
                <p className={styles.paragraph}>Preencha o formulário para receber as suas lentes</p>
                <div className={styles.formLine}>
                    {/* <label for="nome">Nome</label> */}
                    <input name="nome" type="text" placeholder="Nome" className={styles.textfield} onChange={(e) => {
                        handleChange(e, 'nome')
                        // isFormValid(props.values);
                    }} />
                </div>
                <div className={styles.doubleFormLine}>
                    {/* <label for="nome">Telefone</label> */}

                    <div>
                        <label for="telefone" className={styles.birthdateLabel}>Telemóvel</label>
                        <input name="telefone" type="text" placeholder="Telemóvel" className={styles.textfield} onChange={(e) => {
                            handleChange(e, 'telefone')
                            // isFormValid(props.values);
                        }} />
                    </div>
                    <div>
                        <label for="dataNascimento" className={styles.birthdateLabel}>Data de Nascimento</label>
                        <input name="dataNascimento" type="date" max="2002-11-01" className={styles.textfield} onChange={(e) => {
                            handleChange(e, 'dataNascimento')
                            // isFormValid(props.values);
                        }} />
                    </div>
                </div>
                <div className={styles.formLine}>
                    {/* <label for="nome">E-mail</label> */}
                    <input name="email" type="text" placeholder="E-mail" className={styles.textfield} onChange={(e) => {
                        handleChange(e, 'email')
                        // isFormValid(props.values);
                    }} />
                </div>
                <div className={styles.formLine}>

                </div>
                <div className={styles.formLineConsent}>
                    <input type="checkbox" name="consent" value="1" className={styles.checkbox} onChange={(e) => {
                        handleChange(e, 'consent')
                        // isFormValid(props.values);
                    }} />
                    <label for="consent">Autorizo a utilização dos meus dados para ser contactado com informações sobre produtos e promoções, incluindo comunicações personalizadas por email, telefone e outros canais.</label>
                </div>
                <div className={styles.formLine}>
                    <input type="submit" name="submit" className={styles.submitButton} value="Quero Experimentar" onClick={post} disabled={!isValid} />
                </div>

                <div className={styles.formLine}>
                    &nbsp;
                </div>
            </div>
        )
    }
    return (
        <div className={styles.root}>
            <div className={styles.campaignBody}>
                <div className={styles.logoWrapper}>
                    <Logo resolution="100%" stroke="2" />
                </div>
            </div>
            <ScrollToTop />
            <Navigation template={"dark"}/>
            <div className={styles.main}>
                {!complete ?
                    <h1>Experimente <span className={styles.highlight}>GRÁTIS</span> as suas lentes de contacto</h1>
                    :
                    <h1>Obrigado<span className={styles.highlight}>!</span></h1>
                }

                {!complete ?
                    renderForm()
                    :
                    <div>
                        {/* <p className={styles.paragraph}>Obrigado!</p> */}
                        <p className={styles.paragraph}>Em breve, vai receber um email de confirmação.</p>
                        <div className={styles.formLine}>
                            {(Math.floor(Math.random() * 2) < 1) ?
                                <div>
                                    <img src={require('../assets/random/lentes-animacao.gif')} alt="Obrigado!" className={styles.meme}></img>
                                </div>
                                :
                                <div>
                                    <img src={require('../assets/random/lentes-animacao.gif')} alt="Obrigado!" className={styles.meme}></img>
                                </div>
                            }
                        </div>
                        <p className={styles.paragraph_smaller}>Se não receber o email, por favor entre em contacto connosco via telefone.</p>
                        {/* <p className={styles.goBack}>
                            <a href="https://www.oticairis.pt">Voltar ao site www.oticairis.pt</a>
                        </p> */}
                        {/* <div className={styles.logoButton}>
                            <h1>
                                <span className={styles.wrapper}>
                                    <span className={styles.otica}>Ótica</span> <span className={styles.iris}>Íris</span>
                                </span>
                            </h1>
                        </div> */}
                    </div>
                }
            </div>

            <Footer style={styles.fixPosition}/>
        </div >
    )
}

export default CampanhaLentes;