import React from "react";
import * as ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import styles from './modules/Splash.module.css';
import LogoSplash from './LogoSplash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class Splash extends React.Component {
    animateSVG() {

        // a.setAttributeNS(null, "stroke-opacity","0");
        // a.setAttributeNS(null, "stroke-opacity","1");
        setTimeout(function () {
            var node = document.getElementById("titleButton");
            var a = ReactDOM.findDOMNode(node);
            a.style.opacity = 1;
        }, 3900)
        // setTimeout(function () {
        //     var node = document.getElementById("splash");
        //     var a = ReactDOM.findDOMNode(node);
        //     a.style.opacity = 0;
        // }, 5000)
    }
    componentDidMount() {
        this.animateSVG();
    }
    render() {
        return (
            // <div className={styles.itemStyle}></div>
            <div id="splash" className={styles.container}>
                <div className={styles.background}>
                    <div className={styles.logo_wrapper}>
                        <LogoSplash resolution="400" stroke="4" />
                        <div id="titleButton" className={styles.title}>
                            <h1>
                                <span className={styles.wrapper}>
                                    <span className={styles.otica}>Ótica</span> <span className={styles.iris}>Íris</span>
                                </span>
                            </h1>
                            <h3>
                                O futuro da sua visão começa aqui.
                            </h3>
                            {/* <h3 className={styles.title}>
                                <FontAwesomeIcon icon="fas-regular fa-location-dot" className={styles.icon_2} /> <a href="https://www.google.com/maps/dir//Otica%20Iris,%20Braga" target="_blank">Avenida Dom João II 108, 4715-304 Nogueiró - Braga</a>
                            </h3>
                            <h3 className={styles.title}>
                                <FontAwesomeIcon icon="far-regular fa-phone" className={styles.icon} />
                                (+351) 253 173 501 ou (+351) 933 357 399
                            </h3> */}
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}
export default Splash;