import React from "react";
import styles from './modules/TextoAdicional.module.css';

class TextoAdicional extends React.Component {
    render() {
        const { gap } = this.props
        return (
            (gap == "yes") ? (
                <div class={styles.alternativa}></div>

            ) :
                (
                    <div class={styles.alternativa}>
                        <h3 class={styles.alternativah3}>É importante realizar um exame visual a cada 2 anos, mesmo que não sinta alterações na visão!</h3>
                        <p>Temos disponíveis consultas de optometria/contactologia diárias e gratuitas, realizadas pelas nossas optometristas <strong>Cátia Esteves</strong> e <strong>Cláudia Gomes</strong>, ambas licenciadas pela Universidade do Minho e pertencentes à APLO (Associação de Profissionais Licenciados em Optometria).</p>
                        <p>Confie-nos a sua visão e terá um futuro com mais saúde visual.</p>
                    </div>
                )
        )
    }
}

export default TextoAdicional;