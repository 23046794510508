/*

I agree that Calvin Klein will send me promotional and personalized information, including exclusive promotions, 
special offers and invitations to events, via email, social media, the metaverse or otherwise, based on online 
or offline data collected by Calvin Klein and in combination with data received from third parties, including 
social media or other online platforms. See our privacy notice for more information.

*/

import React, { useEffect, useState } from "react";
import styles from './modules/NewsletterSignUp.module.css';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Checkbox, getContrastRatio } from "@mui/material";
// import { createTheme, ThemeProvider, Theme, useTheme } from '@mui/material/styles';
import { alpha, styled } from '@mui/material/styles';
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const CssTextField = styled(TextField)({
    '& .MuiFilledInput-root': {
        backgroundColor: "#efefef",
        color: '#000'

    },
    '& .MuiFilledInput-root:hover': {

        backgroundColor: "#fff !important",

    },
    '& .Mui-focused': {
        backgroundColor: '#fff !important'
    }
});
// const customTheme = (outerTheme) =>
//     createTheme({
//         palette: {
//             white: {
//                 main: '#fff',
//                 light: '#ff0000',
//                 dark: '#00ff00',
//                 contrastText: getContrastRatio('#ccc', '#fff') > 4.5 ? '#fff' : '#000'
//             }
//         },
//         components: {
//             // Inputs
//             MuiOutlinedInput: {
//                 styleOverrides: {
//                     root: {
//                         // backgroundColor: "#eee", // As an example color
//                         "& .MuiOutlinedInput-notchedOutline": {
//                             borderColor: "#ccc !important",
//                             backgroundColor: "#fff"
//                         }
//                     }
//                 }
//             },
//             MuiFormLabel: {
//                 styleOverrides: {
//                     root: {
//                         color: "#ccc"
//                         // '& .MuiInputLabel-root': {
//                         //     color: '#ff0000 !important',
//                         // }
//                     }
//                 }
//             }
//         }
//     })

export default function NewsletterSignUp(props) {
    const [open, setOpen] = React.useState(false);
    const label = { inputProps: { 'aria-label': 'Consent' } };
    const [wasOpened, setWasOpened] = useState(false);
    const { segment } = props

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    // const outerTheme = useTheme();

    const [nome, setNome] = useState('')
    const [email, setEmail] = useState('')
    const [consent, setConsent] = useState(false)
    const [isValid, setValidForm] = useState(false)

    const isFormValid = () => {
        if (nome.length > 5 && email.length > 10 && consent) {
            setValidForm(true);
        }
        else {

            setValidForm(false)
        }
        // console.log('Form is currently: ' + isValid)
    };

    const handleOpen = () => {
        // console.log('Timer kicked.')
        const timer = setTimeout(() => {
            setWasOpened(true)
            setOpen(true);
        }, 4000)
        return () => clearTimeout(timer);
    }
    useEffect(() => {
        if (!wasOpened) {
            handleOpen();
        }
        isFormValid();
    });

    const post = e => {

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
            mode: "cors",
            body: JSON.stringify({
                name: nome,
                email: email,
                consent: consent,
                segment: (!segment ? "" : segment)
            })
        };
        fetch('/api/newsletter/subscribe', requestOptions)
            .then(response => response.json())
            .then(data => {
                // console.log('Response: ' + data)
                handleClose();
                this.formSubmitted(e);
            })
            .catch(error => {
                // console.error('There was an error! --- ', error);
            });

        // setComplete(true)

    }
    const formSubmitted = e => {
        return null;
    }

    return (
        <>
            {segment == "test" ? (
                <div className={styles.hangInThere}>
                    Just hang in there. The popup will come up in no time, bud.
                </div>
            ) : null}

            <Dialog open={open} onClose={handleClose} PaperProps={{
                style: {
                    backgroundColor: '#14345c',
                    // boxShadow: 'none',
                    border: '5px solid #fff'
                },
            }}>
                <DialogTitle className={styles.popupTitle}>Subscreva a nossa newsletter</DialogTitle>
                <DialogContent className={styles.popupContentWrapper}>
                    <DialogContentText className={styles.popupContent}>
                        <div>Todos os meses temos campanhas e promoções novas. Fique a par das últimas novidades. Subscreva a nossa newsletter.<br /><br />
                            <strong>Importante</strong>: Prometemos que recebe no máximo 2 emails por mês.
                        </div>
                        {/* <ThemeProvider theme={customTheme(outerTheme)}> */}
                        <div className={styles.formArea}>
                            <CssTextField
                                autoFocus
                                margin="dense"
                                id="name"
                                label="Nome"
                                type="text"
                                value={nome}
                                onChange={(event) => {
                                    setNome(event.target.value);
                                }}
                                fullWidth
                                variant="filled"
                            />
                            <CssTextField
                                autoFocus
                                margin="dense"
                                id="name"
                                label="Email Address"
                                type="email"
                                value={email}
                                onChange={(event) => {
                                    setEmail(event.target.value);
                                }}
                                fullWidth
                                variant="filled"
                            />
                            {/* </ThemeProvider> */}
                        </div>
                        <div className={styles.consentBox}>
                            <div className={styles.consent}>
                                <Checkbox {...label} required size="small" value="1" checked={consent} sx={{
                                    color: '#ccc',
                                    '&.Mui-checked': {
                                        color: '#eee',
                                    },
                                }} onChange={(event) => {
                                    setConsent(!consent);
                                    console.log('clicked: ' + consent)
                                }} />
                            </div>
                            <div className={styles.consent}>
                                <p>Concordo que a Ótica Íris me envie informações promocionais e personalizadas, incluíndo promoções exclusivas e ofertas especiais, via e-mail com base em dados recolhidos online e offline pela Ótica Íris.</p>
                            </div>
                        </div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {/* <Button onClick={handleClose}>Fechar</Button> */}
                    {/* <Button onClick={handleClose} variant="contained">Inscrever</Button> */}

                    <div className={styles.actionButtons}>
                        <Link onClick={handleClose} className={styles.closeButton}>Fechar</Link>
                        <Link disabled={!isValid} onClick={(isValid ? post : null)}>
                            <div className={styles.buttonAlternative}>
                                Subscrever
                            </div>
                        </Link>
                    </div>
                </DialogActions>
            </Dialog >
        </>
    )

}