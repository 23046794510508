import React from "react";
import styles from './modules/Marcas.module.css';
import { Helmet } from 'react-helmet'

// import rayban from '../assets/marcas/Ray-Ban_logo.svg'
// import hickmann from '../assets/marcas/Ana_Hickmann_logo.svg'
// import bulget from '../assets/marcas/Bulget_logo.svg'
// import carrera from '../assets/marcas/Carrera_logo.svg'
// import hugoboss from '../assets/marcas/Hugo_Boss_logo.svg'
// import polaroid from '../assets/marcas/Polaroid_logo.svg'
// import th from '../assets/marcas/Tommy_Hilfiger_logo.svg'




class Marcas extends React.Component {
    render() {
        return (
            // <div className={styles.itemStyle}></div>
            <div>
                <Helmet>
                    <meta name="description" content="Descobre as nossas marcas para óculos de sol, graduados e lentes de contacto."></meta>
                </Helmet>


                <div className={styles.marcas}>
                    <section>
                        <h2>Eyewear
                            <div className={styles.border}></div>
                        </h2>
                        <div className={styles.logo_wrapper}>
                            <div className={styles.logo_container}>
                                <img src={require('../assets/marcas/ray-ban_logo.png')} alt="Ray Ban" className={styles.brand_logo}></img>
                            </div>
                            <div className={styles.logo_container}>
                                <img src={require('../assets/marcas/Ana_Hickmann_logo.svg').default} alt="Ana Hickmann" className={styles.brand_logo}></img>
                            </div>
                            <div className={styles.logo_container}>
                                <img src={require('../assets/marcas/carolina_herrera.png')} alt="Carolina Herrera" className={styles.brand_logo}></img>
                            </div>
                            <div className={styles.logo_container}>
                                <img src={require('../assets/marcas/Bulget_logo.svg').default} alt="Bulget" className={styles.brand_logo}></img>
                            </div>
                            <div className={styles.logo_container}>
                                <img src={require('../assets/marcas/Carrera_logo.svg').default} alt="Carrera" className={styles.brand_logo}></img>
                            </div>
                            <div className={styles.logo_container}>
                                <img src={require('../assets/marcas/Boss_logo.svg').default} alt="Boss" className={styles.brand_logo}></img>
                            </div>
                            <div className={styles.logo_container}>
                                <img src={require('../assets/marcas/Polaroid_logo.svg').default} alt="Polaroid" className={styles.brand_logo}></img>
                            </div>
                            <div className={styles.logo_container}>
                                <img src={require('../assets/marcas/Tommy_Hilfiger_logo.svg').default} alt="Tommy Hilfiger" className={styles.brand_logo}></img>
                            </div>
                            <div className={styles.logo_container}>
                                <img src={require('../assets/marcas/vogue-eyewear.png')} alt="Vogue Eyewear" className={styles.brand_logo}></img>
                            </div>
                            <div className={styles.logo_container}>
                                <img src={require('../assets/marcas/love-moschino-logo.png')} alt="Love Moschino" className={styles.brand_logo}></img>
                            </div>
                            <div className={styles.logo_container}>
                                <img src={require('../assets/marcas/woodys.png')} alt="Woodys" className={styles.brand_logo}></img>
                            </div>
                            <div className={styles.logo_container}>
                                <img src={require('../assets/marcas/logotipo-Verde-nanovista.svg').default} alt="Nano" className={styles.brand_logo}></img>
                            </div>
                        </div>
                    </section>
                    <div className={styles.divider}></div>
                    <section>
                        <h2 className={styles.white}>Lentes Oftálmicas
                            <div className={styles.border}></div>
                        </h2>
                        <div className={styles.logo_wrapper}>
                            <div className={styles.logo2_container}>
                                <img src={require('../assets/marcas/Zeiss_logo.svg').default} alt="Zeiss" className={styles.brand_logo}></img>
                            </div>
                            <div className={styles.logo_container}>
                                <img src={require('../assets/marcas/Synchrony_logo.png')} alt="Zeiss Synchrony" className={styles.brand_logo}></img>
                            </div>
                            <div className={styles.logo2_container}>
                                <img src={require('../assets/marcas/Essilor_logo.svg').default} alt="Essilor" className={styles.brand_logo}></img>
                            </div>
                            <div className={styles.logo_container}>
                                <img src={require('../assets/marcas/indo-logo.jpg')} alt="Indo" className={styles.brand_logo}></img>
                            </div>
                        </div>
                    </section>
                    <div className={styles.divider}></div>
                    <section>
                        <h2>Lentes de Contacto
                            <div className={styles.border}></div>
                        </h2>
                        <div className={styles.logo_wrapper}>
                            <div className={styles.logo_container}>
                                <img src={require('../assets/marcas/alcon.png')} alt="Alcon" className={styles.brand_logo}></img>
                            </div>
                            <div className={styles.logo_container}>
                                <img src={require('../assets/marcas/coopervision.png')} alt="Coopervision" className={styles.brand_logo}></img>
                            </div>
                            <div className={styles.logo_container}>
                                <img src={require('../assets/marcas/bel.png')} alt="Bausch and Lomb" className={styles.brand_logo}></img>
                            </div>
                            <div className={styles.logo_container}>
                                <img src={require('../assets/marcas/johnson&johnson.png')} alt="Johnson and Johnson" className={styles.brand_logo}></img>
                            </div>
                            <div className={styles.logo_container}>
                                <img src={require('../assets/marcas/menicon.png')} alt="Menicon" className={styles.brand_logo}></img>
                            </div>
                            <div className={styles.logo_container}>
                                <img src={require('../assets/marcas/optiforum.png')} alt="Optiforum" className={styles.brand_logo}></img>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        );
    }
}
export default Marcas;