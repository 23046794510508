import React, { useEffect } from "react";

import TextField from '@mui/material/TextField';
// import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import styles from './modules/Marcacoes.module.css';
import moment from "moment";

const MarcacoesStepTwo = (props) => {
   const { values, handleChange, updateDateTime, isFormValid } = props;
   // const [ value, setValue] = React.useState(moment());

   const minimumDate = moment().add(2, 'H');

   const continuar = e => {
      // e.preventDefault();
      props.nextStep();
   };

   const back = e => {
      // e.preventDefault();
      props.prevStep();
   };
   const filterWeekends = date => {
      var dia = new Date(date);
      if (dia.getDay() == 0) { return true; }
      return;
   }
   useEffect(() => {
      isFormValid();
   });
   return (
      <div className={styles.main}>
         <div className={styles.content}>
            <h1>Agende a sua consulta</h1>
            <div className={styles.formLine}>
               <div className={styles.formInLine}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                     <MobileDatePicker
                        renderInput={(props) => <TextField {...props} />}
                        label="Dia"
                        minDateTime={minimumDate}
                        ampm={false}
                        ampmInClock={false}
                        value={values.datahora}
                        selected={values.datahora}
                        onChange={(newValue) => {
                           // setValue(newValue)
                           updateDateTime(newValue)
                        }}
                        onAccept={updateDateTime}
                        shouldDisableDate={filterWeekends}
                        required
                     />
                  </LocalizationProvider>
               </div>
               <div className={styles.formInLine}>
                  <FormControl variant="outlined">
                  <InputLabel id="hora-select-label">Hora</InputLabel>
                  <Select
                     labelId="hora-select-label"
                     id="hora-select"
                     value={values.hora}
                     onChange={(e) => {
                        handleChange(e, 'hora');
                     }}
                     label="Hora"
                     variant="outlined"
                     defaultValue={1}
                     sx={{ minWidth: 220 }}
                  >
                     <MenuItem value={1}>09:30</MenuItem>
                     <MenuItem value={2}>10:00</MenuItem>
                     <MenuItem value={3}>10:30</MenuItem>
                     <MenuItem value={4}>11:00</MenuItem>
                     <MenuItem value={5}>11:30</MenuItem>
                     <MenuItem value={6}>12:00</MenuItem>
                     <MenuItem value={7}>12:30</MenuItem>
                     <MenuItem value={8} disabled={new Date(values.datahora).getDay() == 6}>13:00</MenuItem>
                     <MenuItem value={9} disabled={new Date(values.datahora).getDay() == 6}>13:30</MenuItem>
                     <MenuItem value={10} disabled={new Date(values.datahora).getDay() == 6}>14:00</MenuItem>
                     <MenuItem value={11} disabled={new Date(values.datahora).getDay() == 6}>14:30</MenuItem>
                     <MenuItem value={12} disabled={new Date(values.datahora).getDay() == 6}>15:00</MenuItem>
                     <MenuItem value={13} disabled={new Date(values.datahora).getDay() == 6}>15:30</MenuItem>
                     <MenuItem value={14} disabled={new Date(values.datahora).getDay() == 6}>16:00</MenuItem>
                     <MenuItem value={15} disabled={new Date(values.datahora).getDay() == 6}>16:30</MenuItem>
                     <MenuItem value={16} disabled={new Date(values.datahora).getDay() == 6}>17:00</MenuItem>
                     <MenuItem value={17} disabled={new Date(values.datahora).getDay() == 6}>17:30</MenuItem>
                     <MenuItem value={18} disabled={new Date(values.datahora).getDay() == 6}>18:00</MenuItem>
                     <MenuItem value={19} disabled={new Date(values.datahora).getDay() == 6}>18:30</MenuItem>
                  </Select>
                  </FormControl>
               </div>
            </div>
            <div className={styles.formLine}>
               <TextField
                  placeholder="Primeiro e ultimo nome"
                  label="Nome"
                  onChange={(e) => {
                     handleChange(e, 'nome')
                     // isFormValid(props.values);
                  }}
                  defaultValue={values.nome}
                  margin="normal"
                  fullWidth
                  variant="standard"
                  required
               />
            </div>
            <div className={styles.formLine}>
               <TextField
                  placeholder="Telefone"
                  label="Telefone"
                  onChange={(e) => {
                     handleChange(e, 'telefone')
                     // isFormValid(props.values);
                  }}
                  defaultValue={values.telefone}
                  margin="normal"
                  fullWidth
                  variant="standard"
                  required
               />
            </div>
            <div className={styles.formLine}>
               <TextField
                  placeholder="Comentários ou dados adicionais"
                  label="Comentários"
                  onChange={(e) => {
                     handleChange(e, 'comentario')
                     // isFormValid(props.values);
                  }}
                  defaultValue={values.comentario}
                  margin="normal"
                  multiline
                  maxRows={4}
                  fullWidth
                  variant="standard"
               />
            </div>
            <div className={styles.formLine}>
               <Button variant="outlined" startIcon={<FontAwesomeIcon icon="fa-solid fa-arrow-left" />} onClick={() => {
                  back();
               }}>
                  Voltar
               </Button>
               <span style={{ "marginLeft": "10px" }}></span>
               <Button variant="contained" endIcon={<FontAwesomeIcon icon="fa-regular fa-calendar" />} onClick={() => {
                  continuar();
               }} className={'button_next_2'} disabled={!values.valid}>
                  Fazer Marcação
               </Button>
            </div>
         </div>
      </div>
   )
}



// goForward = e => {
//    e.preventDefault();
//    this.props.nextStep();
// };
// goBack = e => {
//    e.preventDefault();
//    this.props.prevStep();
// };

// class MarcacoesStepTwo extends React.Component {
//    continue = e => {
//       e.preventDefault();
//       this.props.nextStep();
//    };

//    back = e => {
//       e.preventDefault();
//       this.props.prevStep();
//    };
//    // this.setState({ [input]: e.target.value });

//    render() {
//       const { values, handleChange, updateDateTime } = this.props;
//       // const minimumDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), new Date().getHours()+2, 0, 0);
//       const minimumDate = moment().add(2, 'H');
//       const [value, setValue] = null;
//       return (
//          <div className={styles.main}>
//             <div className={styles.content}>
//                <h1>Agenda a tua consulta</h1>
//                <div>
//                   <TextField
//                      placeholder="Primeiro e ultimo nome"
//                      label="Nome"
//                      onChange={handleChange('nome')}
//                      defaultValue={values.nome}
//                      margin="normal"
//                   />
//                </div>
//                <div>
//                   <TextField
//                      placeholder="Telefone"
//                      label="Telefone"
//                      onChange={handleChange('telefone')}
//                      defaultValue={values.telefone}
//                      margin="normal"
//                   />
//                </div>
//                <div>
//                   <LocalizationProvider dateAdapter={AdapterMoment}>
//                      <DateTimePicker
//                         renderInput={(props) => <TextField {...props} />}
//                         label="Data e Hora"
//                         minDate={minimumDate}
//                         value={values.datahora}
//                         onChange={updateDateTime(moment)}
//                      />
//                   </LocalizationProvider>
//                </div>
//             </div>
//          </div>
//       );
//    }
// }
export default MarcacoesStepTwo;