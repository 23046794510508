import React from "react";
import * as ReactDOM from 'react-dom';
import styles from './modules/LogoSplash.module.css';


class Logo extends React.Component {
    animateSVG() {

        // a.setAttributeNS(null, "stroke-opacity","0");
        // a.setAttributeNS(null, "stroke-opacity","1");
        setTimeout(function () {
            var node = document.getElementById("circleS");
            var a = ReactDOM.findDOMNode(node);
            a.style.strokeOpacity = 1;
        }, 1000)
        setTimeout(function () {
            var node = document.getElementById("oneS");
            var a = ReactDOM.findDOMNode(node);
            a.style.strokeOpacity = 1;
        }, 1500)
        setTimeout(function () {
            var node = document.getElementById("twoS");
            var a = ReactDOM.findDOMNode(node);
            a.style.strokeOpacity = 1;
        }, 1800)
        setTimeout(function () {
            var node = document.getElementById("threeS");
            var a = ReactDOM.findDOMNode(node);
            a.style.strokeOpacity = 1;
        }, 2100)
        setTimeout(function () {
            var node = document.getElementById("fourS");
            var a = ReactDOM.findDOMNode(node);
            a.style.strokeOpacity = 1;
        }, 2400)
        setTimeout(function () {
            var node = document.getElementById("fiveS");
            var a = ReactDOM.findDOMNode(node);
            a.style.strokeOpacity = 1;
        }, 2700)
        setTimeout(function () {
            var node = document.getElementById("sixS");
            var a = ReactDOM.findDOMNode(node);
            a.style.strokeOpacity = 1;
        }, 3000)
        setTimeout(function () {
            var node = document.getElementById("sevenS");
            var a = ReactDOM.findDOMNode(node);
            a.style.strokeOpacity = 1;
        }, 3300)
        setTimeout(function () {
            var node = document.getElementById("eightS");
            var a = ReactDOM.findDOMNode(node);
            a.style.strokeOpacity = 1;
        }, 3600)
        // console.log(a);        
    }
    componentDidMount() {
        this.animateSVG();
    }
    render() {
        return (
            // <div className={styles.itemStyle}></div>
            <div className={styles.container}>
                <svg id="logoSplash" height={this.props.resolution} width={this.props.resolution} fill="transparent" viewBox="0 0 400 400">

                    <ellipse id="oneS" className={styles.one} cx="260" cy="202" rx="100" ry="64" stroke="#2d6179" strokeWidth={this.props.stroke} fill="transparent" transform="rotate(32 200 202)" />

                    <ellipse id="twoS" className={styles.two} cx="260" cy="202" rx="100" ry="64" stroke="#2d6179" strokeWidth={this.props.stroke} fill="transparent" transform="rotate(60 200 202)" />

                    <ellipse id="threeS" className={styles.three} cx="260" cy="202" rx="100" ry="64" stroke="#72aecf" strokeWidth={this.props.stroke} fill="transparent" transform="rotate(118 200 202)" />

                    <ellipse id="fourS" className={styles.four} cx="260" cy="202" rx="100" ry="64" stroke="#72aecf" strokeWidth={this.props.stroke} fill="transparent" transform="rotate(146 200 202)" />

                    <ellipse id="fiveS" className={styles.five} cx="260" cy="202" rx="100" ry="64" stroke="#72d0f3" strokeWidth={this.props.stroke} fill="transparent" transform="rotate(212 200 202)" />

                    <ellipse id="sixS" className={styles.six} cx="260" cy="202" rx="100" ry="64" stroke="#72d0f3" strokeWidth={this.props.stroke} fill="transparent" transform="rotate(240 200 202)" />

                    <ellipse id="sevenS" className={styles.seven} cx="260" cy="202" rx="100" ry="64" stroke="#1c3943" strokeWidth={this.props.stroke} fill="transparent" transform="rotate(300 200 202)" />

                    <ellipse id="eightS" className={styles.eight} cx="260" cy="202" rx="100" ry="64" stroke="#1c3943" strokeWidth={this.props.stroke} fill="transparent" transform="rotate(328 200 202)" />

                    <circle id="circleS" className={styles.circle} cx="200" cy="202" r="45" stroke="#1c3943" strokeWidth={this.props.stroke} fill="white" />
                </svg>
            </div>
        );
    }
}
export default Logo;