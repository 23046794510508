import React from "react";
import styles from './modules/MainContent.module.css';
// import image01 from '../assets/image01.jpg'
// import image01_xs from '../assets/image01_xs.jpg'
import image02 from '../assets/image02.jpg'
import image02_xs from '../assets/image02_xs.jpg'
import hero from '../assets/hero_desktop.jpg'
import hero_xs from '../assets/hero_mobile.jpg'
import natal from '../assets/natal_website.jpg'
import natal_xs from '../assets/natal_mobile.jpg'
import semjuros from '../assets/sem_juros_desktop.jpg'
import semjuros_xs from '../assets/sem_juros_mobile.jpg'
import primeiro from '../assets/1.jpg'
import primeiro_xs from '../assets/1_mobile.jpg'
import segundo from '../assets/2.jpg'
import segundo_xs from '../assets/2_mobile.jpg'
import terceiro from '../assets/3.jpg'
import terceiro_xs from '../assets/3_mobile.jpg'
import quarto from '../assets/4.jpg'
import quarto_xs from '../assets/4_mobile.jpg'
import quinto from '../assets/5.jpg'
import quinto_xs from '../assets/5_mobile.jpg'
import iasfa from '../assets/parceria_iasfa_desktop.jpg'
import iasfa_xs from '../assets/parceria_iasfa_mobile.jpg'
import bf from '../assets/Janeiro_2024.gif'
import bf_xs from '../assets/Janeiro_2024_mobile.jpg'
import imagemUm from '../assets/28022024_1.jpg'
import imagemUm_mobile from '../assets/28022024_1_mobile.jpg'
import imagemDois from '../assets/28022024_2.jpg'
import imagemDois_mobile from '../assets/28022024_2_mobile.jpg'
import imagemTres from '../assets/28022024_3.jpg'
import imagemTres_mobile from '../assets/28022024_3_mobile.jpg'
import abrilOne from '../assets/abril_1.jpg'
import abrilOne_xs from '../assets/abril_1_mobile.jpg'
import abrilTwo from '../assets/abril_2.jpg'
import abrilTwo_xs from '../assets/abril_2_mobile.jpg'
import { Link } from "react-router-dom"

// Import Swiper React components
import { Pagination, A11y, EffectFade, Autoplay } from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';
import 'swiper/css/effect-fade'
import Protocolos from "./Protocolos";
import Box from "@mui/material/Box";
import NewsletterSignUp from "./NewsletterSignUp";

function isNovember() {
   var hoje = new Date();
   var mes = hoje.getMonth() + 1;
   var hora = hoje.getHours()
   if (mes == 11 && (hoje.getDate() > 1 || (hoje.getDate() == 1 && hora >= 21))) {
      return (
         <SwiperSlide>
            <div className={styles.videoSlide_desktop}>
               <video autoPlay={true} id="video1" muted loop playsInline className={styles.videoSlide}>
                  <source src={require('../assets/black_november_2400.mp4')} />
               </video>
            </div>
            <div className={styles.videoSlide_mobile}>
               <video autoPlay={true} id="video2" muted loop playsInline className={styles.videoSlide}>
                  <source src={require('../assets/BLACK NOVEMBER MOBILE.mp4')} />
               </video>
            </div>

         </SwiperSlide>
      )
   }
}

function doBanner(banner) {
   var code = ""

   code = (
      <SwiperSlide>
         <Box component="img" src={banner.image} className={styles.main_image} sx={{ display: { xs: 'none', md: 'block' } }} />
         <Box component="img" src={banner.imageMobile} className={styles.main_image} sx={{ display: { xs: 'block', md: 'none' } }} />
         %%options%%
      </SwiperSlide>
   )
   if (banner.button) {
      if (banner.buttonPosition == 'L') {

      }
   }
   return (code)
}


class MainContent extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         data: [],
         isLoading: true
      };
   }

   componentDidMount() {
      this.fetchData();
   }

   fetchData = async () => {
      try {
         const response = await fetch('/api/banners/');
         const jsonData = await response.json();
         this.setState({ data: jsonData });
         if (jsonData.banners.length > 0) {
            this.setState({ isLoading: false });
         }

      } catch (error) {
         console.error('Error fetching data:', error);
      }
   };
   render() {
      const { data, isLoading } = this.state;
      let hero_banners;
      let static_content;

      static_content = (
         <div className={styles.main}>
            <div className={styles.content}>
               <Swiper
                  modules={[Pagination, A11y, EffectFade, Autoplay]}
                  effect="fade"
                  slidesPerView={1}
                  pagination={{ clickable: true }}
                  autoplay={{
                     delay: 10000,
                     disableOnInteraction: true,
                  }}
               >
                  {hero_banners}
               </Swiper>
            </div>
            <NewsletterSignUp />
            <div id="servicos" className={styles.content}>
               <h1 className={styles.services}>Venha conhecer os nossos serviços</h1>
               <h4>Dispomos de todos os serviços necessários para cuidarmos da sua visão da melhor forma.</h4>
               <div className={styles.servicos_content}>
                  <div className={styles.servicos_item}><h3><FontAwesomeIcon icon="fa-solid fa-eye" className={styles.icon} />Optometria e Contactologia</h3>
                     Realizamos consultas diárias, de forma gratuita e aconselhamos as lentes mais apropriadas para si.</div>
                  <div className={styles.servicos_item}><h3><FontAwesomeIcon icon="fa-solid fa-user-doctor" className={styles.icon} />Retinografia</h3>
                     Avaliamos o fundo ocular para despiste de eventuais patologias.</div>
                  <div className={styles.servicos_item}><h3><FontAwesomeIcon icon="fa-solid fa-bullseye" className={styles.icon} />Tonometria</h3>
                     Medimos a pressão intraocular e fazemos o seu acompanhamento, caso necessite.</div>
                  <div className={styles.servicos_item}><h3><FontAwesomeIcon icon="fa-solid fa-id-card" className={styles.icon} />Declaração para carta de condução</h3>
                     Emitimos o documento necessário para a renovação da carta de condução.</div>
               </div>
               <div className={styles.content_cta}>
                  <Link to="/marcacoes/">
                     <div className={styles.buttonAlternative}>
                        Agende já a <span className={styles.iris}>sua consulta</span>
                     </div>
                  </Link>
               </div>
            </div>
            <div className={styles.content_map}>
               <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2985.8067793014084!2d-8.397875384569085!3d41.55178067924944!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd24fdb6d02925a3%3A0xd7673d252cd842a9!2zw5N0aWNhIMONcmlz!5e0!3m2!1sen!2snl!4v1664709112669!5m2!1sen!2snl" width="100%" height="450" style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
            <Protocolos />
         </div>
      )

      if (isLoading) {
         hero_banners = (
            <>
               <SwiperSlide>
                  <Box component="img" src={terceiro} alt="Agende já a sua consulta" className={styles.main_image} sx={{ display: { xs: 'none', md: 'block' } }} />
                  <Box component="img" src={terceiro_xs} alt="Agende já a sua consulta" className={styles.main_image} sx={{ display: { xs: 'block', md: 'none' } }} />
                  <div className={styles.image_overlay_3}>
                     <Link to="/marcacoes/">
                        <div className={styles.buttonAlternative}>
                           Agende já a sua consulta
                        </div>
                        <div className={styles.button_icon_mobile}>
                           <FontAwesomeIcon icon="fa-regular fa-calendar-plus" />
                        </div>
                     </Link>
                  </div>
               </SwiperSlide>
               <SwiperSlide>
                  <Box component="img" src={imagemTres} alt="Pague 12x. Sem Juros" className={styles.main_image} sx={{ display: { xs: 'none', md: 'block' } }} />
                  <Box component="img" src={imagemTres_mobile} alt="Pague 12x. Sem Juros" className={styles.main_image} sx={{ display: { xs: 'block', md: 'none' } }} />
               </SwiperSlide>
            </>
         );
      }
      else {
         hero_banners = null
         let child_hero = []
         this.state.data.banners.map((item, i) => {
            // console.log("CALATE -- " + item.buttonText)
            child_hero.push(
               <SwiperSlide>
                  <Box component="img" src={item.image} className={styles.main_image} sx={{ display: { xs: 'none', md: 'block' } }} />
                  <Box component="img" src={item.imageMobile} className={styles.main_image} sx={{ display: { xs: 'block', md: 'none' } }} />
                  {(item.button && item.buttonPosition == 'L') ? (
                     <div className={styles.image_overlay_4}>
                        <Link to={item.buttonLink}>
                           <div className={styles.button_2}>
                              Experimente <span className={styles.text_orange}>GRÁTIS</span>
                           </div>
                        </Link>
                     </div>
                  ) : (null)}
                  {(item.button && item.buttonPosition == 'R') ? (
                     <div className={styles.image_overlay_3}>
                        <Link to={item.buttonLink}>
                           <div className={styles.buttonAlternative}>
                              {item.buttonText}
                           </div>
                           <div className={styles.button_icon_mobile}>
                              <FontAwesomeIcon icon="fa-regular fa-calendar-plus" />
                           </div>
                        </Link>
                     </div>
                  ) : (null)}

               </SwiperSlide>
            )
            // return doBanner(item)
         })
         hero_banners = (
            <>
               {
                  child_hero.map((item, i) => {
                     return (
                        item
                     )
                  })
               }
            </>
         )
      }
      return (
         <div className={styles.main}>
            <div className={styles.content}>
               <Swiper
                  modules={[Pagination, A11y, EffectFade, Autoplay]}
                  effect="fade"
                  slidesPerView={1}
                  pagination={{ clickable: true }}
                  autoplay={{
                     delay: 10000,
                     disableOnInteraction: true,
                  }}
               >
                  {hero_banners}
               </Swiper>
            </div>
            <NewsletterSignUp />
            <div id="servicos" className={styles.content}>
               <h1 className={styles.services}>Venha conhecer os nossos serviços</h1>
               <h4>Dispomos de todos os serviços necessários para cuidarmos da sua visão da melhor forma.</h4>
               <div className={styles.servicos_content}>
                  <div className={styles.servicos_item}><h3><FontAwesomeIcon icon="fa-solid fa-eye" className={styles.icon} />Optometria e Contactologia</h3>
                     Realizamos consultas diárias, de forma gratuita e aconselhamos as lentes mais apropriadas para si.</div>
                  <div className={styles.servicos_item}><h3><FontAwesomeIcon icon="fa-solid fa-user-doctor" className={styles.icon} />Retinografia</h3>
                     Avaliamos o fundo ocular para despiste de eventuais patologias.</div>
                  <div className={styles.servicos_item}><h3><FontAwesomeIcon icon="fa-solid fa-bullseye" className={styles.icon} />Tonometria</h3>
                     Medimos a pressão intraocular e fazemos o seu acompanhamento, caso necessite.</div>
                  <div className={styles.servicos_item}><h3><FontAwesomeIcon icon="fa-solid fa-id-card" className={styles.icon} />Declaração para carta de condução</h3>
                     Emitimos o documento necessário para a renovação da carta de condução.</div>
               </div>
               <div className={styles.content_cta}>
                  <Link to="/marcacoes/">
                     <div className={styles.buttonAlternative}>
                        Agende já a <span className={styles.iris}>sua consulta</span>
                     </div>
                  </Link>
               </div>
            </div>
            <div className={styles.content_map}>
               <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2985.8067793014084!2d-8.397875384569085!3d41.55178067924944!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd24fdb6d02925a3%3A0xd7673d252cd842a9!2zw5N0aWNhIMONcmlz!5e0!3m2!1sen!2snl!4v1664709112669!5m2!1sen!2snl" width="100%" height="450" style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
            <Protocolos />
         </div>
      );
   }
}
export default MainContent;