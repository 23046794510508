import React from "react";
import styles from './modules/Logo-dark.module.css';


class LogoSolid extends React.Component {
    render() {
        return (
            // <div className={styles.itemStyle}></div>
            <div className={styles.container}>
                <svg height={this.props.resolution} width={this.props.resolution} fill="transparent" viewBox="0 0 400 400">

                    <ellipse id="one" cx="260" cy="202" rx="100" ry="64" stroke="#2d6179" strokeWidth={this.props.stroke} fill="transparent" transform="rotate(32 200 202)" />
                    <ellipse id="one2" className={styles.one} cx="260" cy="202" rx="100" ry="64" stroke="#2d6179" strokeWidth={this.props.stroke} fill="transparent" transform="rotate(32 200 202)" />

                    <ellipse id="two" cx="260" cy="202" rx="100" ry="64" stroke="#2d6179" strokeWidth={this.props.stroke} fill="transparent" transform="rotate(60 200 202)" />
                    <ellipse id="two2" className={styles.two} cx="260" cy="202" rx="100" ry="64" stroke="#2d6179" strokeWidth={this.props.stroke} fill="transparent" transform="rotate(60 200 202)" />

                    <ellipse id="three" cx="260" cy="202" rx="100" ry="64" stroke="#72aecf" strokeWidth={this.props.stroke} fill="transparent" transform="rotate(118 200 202)" />
                    <ellipse id="three2" className={styles.three} cx="260" cy="202" rx="100" ry="64" stroke="#72aecf" strokeWidth={this.props.stroke} fill="transparent" transform="rotate(118 200 202)" />

                    <ellipse id="four" cx="260" cy="202" rx="100" ry="64" stroke="#72aecf" strokeWidth={this.props.stroke} fill="transparent" transform="rotate(146 200 202)" />
                    <ellipse id="four2" className={styles.four} cx="260" cy="202" rx="100" ry="64" stroke="#72aecf" strokeWidth={this.props.stroke} fill="transparent" transform="rotate(146 200 202)" />

                    <ellipse id="five" cx="260" cy="202" rx="100" ry="64" stroke="#72d0f3" strokeWidth={this.props.stroke} fill="transparent" transform="rotate(212 200 202)" />
                    <ellipse id="five2" className={styles.five} cx="260" cy="202" rx="100" ry="64" stroke="#72d0f3" strokeWidth={this.props.stroke} fill="transparent" transform="rotate(212 200 202)" />

                    <ellipse id="six" cx="260" cy="202" rx="100" ry="64" stroke="#72d0f3" strokeWidth={this.props.stroke} fill="transparent" transform="rotate(240 200 202)" />
                    <ellipse id="six2" className={styles.six} cx="260" cy="202" rx="100" ry="64" stroke="#72d0f3" strokeWidth={this.props.stroke} fill="transparent" transform="rotate(240 200 202)" />

                    <ellipse id="seven" cx="260" cy="202" rx="100" ry="64" stroke="#1c3943" strokeWidth={this.props.stroke} fill="transparent" transform="rotate(300 200 202)" />
                    <ellipse id="seven2" className={styles.seven} cx="260" cy="202" rx="100" ry="64" stroke="#1c3943" strokeWidth={this.props.stroke} fill="transparent" transform="rotate(300 200 202)" />

                    <ellipse id="eight" cx="260" cy="202" rx="100" ry="64" stroke="#1c3943" strokeWidth={this.props.stroke} fill="transparent" transform="rotate(328 200 202)" />
                    <ellipse id="eight2" className={styles.eight} cx="260" cy="202" rx="100" ry="64" stroke="#1c3943" strokeWidth={this.props.stroke} fill="transparent" transform="rotate(328 200 202)" />
                    {/* <circle cx="200" cy="202" r="45" stroke="#1c3943" stroke-width="2" fill="white" /> */}
                </svg>
            </div>
        );
    }
}
export default LogoSolid;