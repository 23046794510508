import React, { Component } from 'react';
import moment from "moment";
import { Helmet } from 'react-helmet'

import MarcacoesStepOne from './MarcacoesStepOne';
import MarcacoesStepTwo from './MarcacoesStepTwo';
import MarcacoesConfirm from './MarcacoesConfirm';
import MarcacoesSuccess from './MarcacoesSuccess';
import TextoAdicional from './TextoAdicional';

import styles from './modules/Marcacoes.module.css';

export class Marcacoes extends Component {
  state = {
    step: 1,
    nome: '',
    telefone: '',
    tipo: '',
    datahora: moment().add(((moment().day() == 6) ? 2 : 1), 'd'),
    hora: 1,
    comentario: '',
    valid: false
  };

  // Proceed to next step
  nextStep = () => {
    const { step } = this.state;
    this.setState({
      step: step + 1
    });
  };

  // Go back to prev step
  prevStep = () => {
    const { step } = this.state;
    this.setState({
      step: step - 1
    });
  };
  updateTime(value) {
    var hora = 9 + (value % 2 != 0 ? Math.floor(value / 2) : value / 2);
    var minutos = 0
    if (value % 2 != 0) {
      minutos = 30
    }
    var novaData = new Date(this.state.datahora)
    novaData.setHours(hora)
    novaData.setMinutes(minutos)
    novaData.setSeconds(0)
    // console.log("Data hora: " + novaData)
    this.setState({
      datahora: novaData
    });
  }
  // Handle fields change
  handleChange = (e, input) => {
    // console.log('Valor mudou: ' + e.target.value);
    this.setState({ [input]: e.target.value });
    // this.isFormValid();
    if (input == "hora") {
      // console.log('tenho de calcular ' + this.state.datahora)
      this.updateTime(e.target.value)
    }

  };
  updateDateTime = (value, e) => {
    // console.log('data: ' + value)
    this.setState({ ['datahora']: moment(value) });
    if (new Date(value).getDay() == 6 && this.state.hora > 7) {
      this.setState({ ['hora']: 1 });
      this.updateTime(1)
    }
    console.log('hora actual: ' + this.state.hora)

  };
  updateTipo = value => {
    this.setState({ tipo: value });
  };
  isFormValid = () => {
    // console.log('Nome: ' + this.state.nome.length + ' Telefone: ' + this.state.telefone.length + ' Data: ' + this.state.datahora)
    if (this.state.nome.length > 5 && this.state.telefone.length > 8 && this.state.datahora != '') {
      this.setState({ valid: true });
    }
    else {
      this.setState({ valid: false });
    }
  }
  render() {
    const { step } = this.state;
    const { nome, telefone, tipo, datahora, hora, comentario, valid } = this.state;
    const values = { nome, telefone, tipo, datahora, hora, comentario, valid };

    <Helmet>
      <meta name="description" content="Faz já a tua marcação para Consulta de Optometria, Experimentação de Lentes de Contacto ou para a Renovação da Carta de Condução."></meta>
    </Helmet>

    switch (step) {
      case 1:
        return (
          <>
            <MarcacoesStepOne
              nextStep={this.nextStep}
              handleChange={this.handleChange}
              updateTipo={this.updateTipo}
              values={values}
            />
            <TextoAdicional/>
          </>
        );
      case 2:
        return (
          <>
            <MarcacoesStepTwo
              nextStep={this.nextStep}
              prevStep={this.prevStep}
              handleChange={this.handleChange}
              updateDateTime={this.updateDateTime}
              isFormValid={this.isFormValid}
              values={values}
            />
            <TextoAdicional/>
          </>
        );
      case 3:
        return (
          <>
            <MarcacoesConfirm
              nextStep={this.nextStep}
              prevStep={this.prevStep}
              values={values}
            />
            <TextoAdicional gap="yes"/>
          </>
        );
      case 4:
        return <MarcacoesSuccess
          values={values}
        />;
      default:
        (console.log('This is a multi-step form built with React.'))
    }
  }
}

export default Marcacoes;