import React from "react";
import { Link } from 'react-router-dom';
import styles from './modules/Navigation.module.css';
import Logo from './Logo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Stack from '@mui/material/Stack';
import MailIcon from '@mui/icons-material/Mail';
import { withScrollToTop } from "./ScrollToTop";


const Navigation = (props) => {

   const [state, setState] = React.useState({
      menu: false
   });

   const toggleDrawer = (open) => (event) => {
      if (
         event.type === "keydown" &&
         (event.key === "Tab" || event.key === "Shift")
      ) {
         return;
      }

      setState({ ...state, menu: open });
   };
   const { template } = props;
   // console.log("Template value: " + template);

   const list = () => (
      <Box
         sx={{ width: 250 }}
         role="presentation"
         onClick={toggleDrawer(false)}
         onKeyDown={toggleDrawer(false)}
      >
         <List>
            <ListItem disablePadding>
               <ListItemButton>
                  <ListItemIcon>
                     <FontAwesomeIcon icon="fa-solid fa-minus" className={styles.icon} />
                  </ListItemIcon>
                  <Link to="/" className={styles.navBar}><ListItemText primary="Home" /></Link>
               </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
               <ListItemButton>
                  <ListItemIcon>
                     <FontAwesomeIcon icon="fa-solid fa-minus" className={styles.icon} />
                  </ListItemIcon>
                  <Link to="/marcacoes/" className={styles.navBar}><ListItemText primary="Marcações" /></Link>
               </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
               <ListItemButton>
                  <ListItemIcon>
                     <FontAwesomeIcon icon="fa-solid fa-minus" className={styles.icon} />
                  </ListItemIcon>
                  <Link to="/parceiros/" className={styles.navBar}><ListItemText primary="Parceiros" /></Link>
               </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
               <ListItemButton>
                  <ListItemIcon>
                     <FontAwesomeIcon icon="fa-solid fa-minus" className={styles.icon} />
                  </ListItemIcon>
                  <Link to="/marcas/" className={styles.navBar}><ListItemText primary="Marcas" /></Link>
               </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
               <ListItemButton>
                  <ListItemIcon>
                     <FontAwesomeIcon icon="fa-solid fa-minus" className={styles.icon} />
                  </ListItemIcon>

                  <Link to="/campanhas/" className={styles.navBar}><ListItemText primary="Campanhas" /></Link>
                  <Badge badgeContent={1} color="warning" className={styles.badgeHM} />

               </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
               <ListItemButton>
                  <ListItemIcon>
                     <FontAwesomeIcon icon="fa-solid fa-minus" className={styles.icon} />
                  </ListItemIcon>
                  <Link to="/sobre/" className={styles.navBar}><ListItemText primary="Sobre" /></Link>
               </ListItemButton>
            </ListItem>
         </List>
         <Divider />
         <List>
         <ListItem disablePadding>
               <ListItemButton>
                  <ListItemIcon>
                     <FontAwesomeIcon icon="fa-solid fa-user" className={styles.icon} />
                  </ListItemIcon>
                  <a href="/cliente/" className={styles.navBar}>
                     <ListItemText primary="Área Cliente" />
                  </a>
               </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
               <ListItemButton>
                  <ListItemIcon>
                     <FontAwesomeIcon icon="far-regular fa-phone" className={styles.icon} />
                  </ListItemIcon>
                  <a href="tel:+351933357399" className={styles.navBar}>
                     <ListItemText primary="(+351) 253 173 501" />
                     <p className={styles.footnote}>Chamada para a rede fixa nacional</p>
                  </a>

               </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
               <ListItemButton>
                  <ListItemIcon>
                     <FontAwesomeIcon icon="fa-solid fa-mobile-button" className={styles.icon} />
                  </ListItemIcon>
                  <a href="tel:+351933357399" className={styles.navBar}>
                     <ListItemText primary="(+351) 933 357 399" />
                     <p className={styles.footnote}>Chamada para a rede móvel nacional</p>
                  </a>

               </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
               <ListItemButton>
                  <ListItemIcon>
                     <FontAwesomeIcon icon="fa-solid fa-diamond-turn-right" className={styles.icon} />
                  </ListItemIcon>
                  <a href="https://www.google.com/maps/dir//Otica%20Iris,%20Braga" target="_blank" className={styles.navBar}>
                     <ListItemText primary="Direções" />
                  </a>
               </ListItemButton>
            </ListItem>
         </List>
      </Box>
   );
   return (
      // , position: 'sticky', top: 0, left: 0, zIndex: 10, backgroundColor: '#fff'
      <div style={{ display: 'flex', justifyContent: 'left' }}>
         <Box onClick={toggleDrawer(true)} sx={{ display: { xs: 'block', md: 'none' } }} className={styles.AppNavigationBar}>
            <IconButton
               size="large"
               edge="start"
               color="inherit"
               aria-label="menu"
               sx={{ mr: 2 }}

            >
               {template == "dark" ?
                  <MenuIcon className={styles.hamburgerMenu_inverted} />
                  :
                  <MenuIcon />
               }
            </IconButton>
         </Box>
         <Box className={styles.NavBarTitle} sx={{ display: { xs: 'sticky', md: 'none' } }} style={{ zIndex: '-1' }}>
            <Logo resolution="40" stroke="4" />
         </Box>
         <Drawer
            anchor="left"
            open={state["menu"]}
            onClose={toggleDrawer(false)}
         >
            {list()}
         </Drawer>

         <Box sx={{ display: { xs: 'none', md: 'block' } }} style={{ width: '100%' }}>
            <div className={styles.container}>
               <div className={styles.sub_container}>
                  <div className={styles.logo}><Link to="/"><Logo resolution="40" stroke="4" /></Link></div>
                  <div className={styles.item}><Link to="/marcacoes/">Marcações</Link></div>
                  <div className={styles.item}><Link to="/parceiros/">Parceiros</Link></div>
                  <div className={styles.item}><Link to="/marcas/">Marcas</Link></div>
                  <div className={styles.item}>
                     {/* <Stack spacing={2} direction="row">
                        <Badge badgeContent={4} color="red">
                           <Link to="/campanha-lentes">Campanhas</Link>
                        </Badge>
                     </Stack> */}
                     <Stack spacing={2} direction="row">
                        <Badge badgeContent={1} color="warning">
                           <Link to="/campanhas/">Campanhas</Link>
                        </Badge>
                     </Stack>
                  </div>
                  <div className={styles.item}><Link to="/sobre/">Sobre</Link></div>
                  {/* <div className={styles.item}><Link to="/equipa">A nossa Equipa</Link></div> */}
               </div>
               <div className={styles.sub_container}>
                  <div className={styles.item}>
                     <a href="/cliente/" className={styles.navBar}>
                        <FontAwesomeIcon icon="far-regular fa-user" className={styles.icon} /> Área Cliente
                     </a>
                  </div>
                  <div className={styles.item}>
                     <a href="tel:+351933357399" className={styles.navBar}>
                        <FontAwesomeIcon icon="far-regular fa-phone" className={styles.icon} /> Ligue-nos
                     </a>
                  </div>
                  <div className={styles.item}>
                     <a href="https://www.google.com/maps/dir//Otica%20Iris,%20Braga" target="_blank">
                        <FontAwesomeIcon icon="fa-solid fa-diamond-turn-right" className={styles.icon} /> Direcções
                     </a>
                  </div>
               </div>
            </div>
         </Box>
      </div>
   );
}
export default withScrollToTop(Navigation);