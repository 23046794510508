import React from "react";
import { Helmet } from "react-helmet";
import styles from './modules/Protocolos.module.css';

// import rayban from '../assets/marcas/Ray-Ban_logo.svg'
// import hickmann from '../assets/marcas/Ana_Hickmann_logo.svg'
// import bulget from '../assets/marcas/Bulget_logo.svg'
// import carrera from '../assets/marcas/Carrera_logo.svg'
// import hugoboss from '../assets/marcas/Hugo_Boss_logo.svg'
// import polaroid from '../assets/marcas/Polaroid_logo.svg'
// import th from '../assets/marcas/Tommy_Hilfiger_logo.svg'




class Protocolos extends React.Component {
    render() {
        return (
            // <div className={styles.itemStyle}></div>
            <div className={styles.protocolos}>
                <Helmet>
                    <meta name="description" content="As vantagens que oferecemos através dos nossos protocolos, estendem-se desde descontos diretos em lentes graduadas e armações até outros produtos óticos."></meta>
                </Helmet>
                <section>
                    <h2>Parceiros
                        <div className={styles.border}></div>
                    </h2>

                    <div className={styles.about}><p>Na Ótica Íris acreditamos que para chegarmos cada vez mais longe precisamos de nos rodear de parceiros que acreditem em nós
                        e nos ajudem a chegar a mais pessoas. Só desta forma conseguiremos cumprir o nosso principal objetivo: cuidar da saúde visual da comunidade bracarense.
                        Estabelecemos vários protocolos nas mais diversas áreas para garantirmos que levamos a Ótica Íris a todos vós.
                        <span className={styles.highlight}> As vantagens que oferecemos estendem-se desde descontos diretos em lentes graduadas e armações até outros produtos óticos </span>
                        que comercializamos na nossa loja.</p>
                    </div>

                    <div className={styles.logo_wrapper}>
                    <div className={styles.logo_container}>
                            <img src={require('../assets/parcerias/iasfa.png')} alt="Instituto de Ação Social das Forças Armadas" className={styles.brand_logo}></img>
                        </div>
                        <div className={styles.logo_container}>
                            <img src={require('../assets/parcerias/ssgnr-logo.png')} alt="Serviços Sociais da GNR" className={styles.brand_logo}></img>
                        </div>
                        <div className={styles.logo_container}>
                            <img src={require('../assets/parcerias/sams-quadros.png')} alt="SAMS Quadros" className={styles.brand_logo}></img>
                        </div>
                        <div className={styles.logo_container}>
                            <img src={require('../assets/parcerias/logo-medicare.png')} alt="Medicare" className={styles.brand_logo}></img>
                        </div>
                        <div className={styles.logo_container}>
                            <img src={require('../assets/parcerias/allianz.png')} alt="Allianz" className={styles.brand_logo}></img>
                        </div>
                        <div className={styles.logo_container}>
                            <img src={require('../assets/parcerias/Multicare.png')} alt="Multicare Fidelidade" className={styles.brand_logo}></img>
                        </div>
                        <div className={styles.logo_container}>
                            <img src={require('../assets/parcerias/OrdemEnfermeiros.png')} alt="Ordem dos Enfermeiros Norte" className={styles.brand_logo}></img>
                        </div>
                        <div className={styles.logo_container}>
                            <img src={require('../assets/parcerias/ordem-dos-advogados-logo.png')} alt="Ordem dos Advogados" className={styles.brand_logo}></img>
                        </div>
                        <div className={styles.logo_container}>
                            <img src={require('../assets/parcerias/occ_novo_hor.png')} alt="Ordem dos Contabilistas Certificados" className={styles.brand_logo}></img>
                        </div>
                        <div className={styles.logo_container}>
                            <img src={require('../assets/parcerias/oern-ordem-engenheiros-regiao-norte-logo.png')} alt="Ordem dos Engenheiros Região Norte" className={styles.brand_logo}></img>
                        </div>
                        <div className={styles.logo_container}>
                            <img src={require('../assets/parcerias/sindicato-professores-zona-norte.png')} alt="Sindicado de Professores - Zona Norte" className={styles.brand_logo}></img>
                        </div>
                        <div className={styles.logo_container}>
                            <img src={require('../assets/parcerias/jeronimo-martins.png')} alt="Jerónimo Martins" className={styles.brand_logo}></img>
                        </div>
                        <div className={styles.logo_container}>
                            <img src={require('../assets/parcerias/hospital braga.png')} alt="Hospital de Braga " className={styles.brand_logo}></img>
                        </div>
                        <div className={styles.logo_container}>
                            <img src={require('../assets/parcerias/cachapuz.png')} alt="Cachapuz" className={styles.brand_logo}></img>
                        </div>
                        <div className={styles.logo_container}>
                            <img src={require('../assets/parcerias/PRIMAVERA.png')} alt="Grupo Primavera" className={styles.brand_logo}></img>
                        </div>
                        <div className={styles.logo_container}>
                            <img src={require('../assets/parcerias/SCBRAGA.png')} alt="Sporting Clube de Braga" className={styles.brand_logo}></img>
                        </div>
                        <div className={styles.logo_container}>
                            <img src={require('../assets/parcerias/CSVH.png')} alt="Centro Social do Vale do Homem" className={styles.brand_logo}></img>
                        </div>
                        <div className={styles.logo_container}>
                            <img src={require('../assets/parcerias/csbj.png')} alt="Casa de Saude do Bom Jesus" className={styles.brand_logo}></img>
                        </div>
                        <div className={styles.logo_container}>
                            <img src={require('../assets/parcerias/COPEFI.png')} alt="COPEFI - Automotive Components" className={styles.brand_logo}></img>
                        </div>                        
                        <div className={styles.logo_container}>
                            <img src={require('../assets/parcerias/PDAUTO.png')} alt="Grupo PDAuto" className={styles.brand_logo}></img>
                        </div>
                        <div className={styles.logo_container}>
                            <img src={require('../assets/parcerias/Logo-Braval.jpg')} alt="Braval" className={styles.brand_logo}></img>
                        </div>
                        <div className={styles.logo_container}>
                            <img src={require('../assets/parcerias/esca.png')} alt="Agrupamento de Escolas Carlos Amarante" className={styles.brand_logo}></img>
                        </div>
                        <div className={styles.logo_container}>
                            <img src={require('../assets/parcerias/Tub.jpg')} alt="CRPT - Clube Pessoal Transportes Urbanos de Braga" className={styles.brand_logo}></img>
                        </div>
                        <div className={styles.logo_container}>
                            <img src={require('../assets/parcerias/escala78.png')} alt="Escala78, Engenharia e Construção" className={styles.escala}></img>
                        </div>
                        
                        <div className={styles.logo_container}>
                            <img src={require('../assets/parcerias/nova era.jpg')} alt="Nova Era Serralharia" className={styles.brand_logo}></img>
                        </div>
                        
                        <div className={styles.logo_container}>
                            <img src={require('../assets/parcerias/millennium.png')} alt="Millennium" className={styles.brand_logo}></img>
                        </div>
                        <div className={styles.logo_container}>
                            <img src={require('../assets/parcerias/servimed-logo.jpg')} alt="Servimed - Rede de Assistência Médica" className={styles.brand_logo}></img>
                        </div>
                        <div className={styles.logo_container}>
                            <img src={require('../assets/parcerias/Staples-Logo.png')} alt="Staples" className={styles.brand_logo}></img>
                        </div>

                        <div className={styles.logo_container}>
                            <img src={require('../assets/parcerias/Makro_Europe_logo.png')} alt="Makro" className={styles.brand_logo}></img>
                        </div>
                        <div className={styles.logo_container}>
                            <img src={require('../assets/parcerias/acp.png')} alt="ACP - Automóvel Club de Portugal" className={styles.brand_logo}></img>
                        </div>
                        <div className={styles.logo_container}>
                            <img src={require('../assets/parcerias/deloitte.png')} alt="Deloitte" className={styles.brand_logo}></img>
                        </div>
                        <div className={styles.logo_container}>
                            <img src={require('../assets/parcerias/antram.png')} alt="Associação Nacional de Transportadores Públicos Rodoviários de Mercadorias" className={styles.brand_logo}></img>
                        </div>
                        <div className={styles.logo_container}>
                            <img src={require('../assets/parcerias/holmes place.png')} alt="Holmes Place" className={styles.brand_logo}></img>
                        </div>
                        
                        <div className={styles.logo_container}>
                            <img src={require('../assets/parcerias/logo-andy.png')} alt="Andy" className={styles.brand_logo}></img>
                        </div>
                        <div className={styles.logo_container}>
                            <img src={require('../assets/parcerias/Grupo-Navarra.jpg')} alt="Grupo Navarra" className={styles.brand_logo}></img>
                        </div>
                        <div className={styles.logo_container}>
                            <img src={require('../assets/parcerias/logo-de-braga.jpeg')} alt="MELIÃ BRAGA" className={styles.brand_logo}></img>
                        </div>
                    </div>
                    <div className={styles.about}>
                        <br />
                        A todos os nossos parceiros que nos abriram as suas portas e nos deram a oportunidade de levarmos a Ótica Íris até aos seus colaboradores fica aqui o nosso agradecimento.<br /><br />
                        <span className={styles.highlight}>Se pretende obter informações sobre os descontos ou se é uma empresa que pretende estabelecer uma parceria connosco  <a href="tel:+351933357399" className={styles.navBar}>contacte-nos</a></span>.</div>
                </section>
                {/* <div className={styles.divider}></div>
                <section>
                    <h2 className={styles.white}>Lentes Oftálmicas
                        <div className={styles.border}></div>
                    </h2>
                    <div className={styles.logo_wrapper}>
                        <div className={styles.logo_container}>
                            <img src={require('../assets/marcas/Zeiss_logo.svg').default} alt="Zeiss" className={styles.brand_logo}></img>
                        </div>
                        <div className={styles.logo_container}>
                            <img src={require('../assets/marcas/Synchrony_logo.png')} alt="Zeiss Synchrony" className={styles.brand_logo}></img>
                        </div>
                        <div className={styles.logo_container}>
                            <img src={require('../assets/marcas/Essilor_logo.svg').default} alt="Essilor" className={styles.brand_logo}></img>
                        </div>
                    </div>
                </section>
                <div className={styles.divider}></div>
                <section>
                    <h2>Lentes de Contacto
                        <div className={styles.border}></div>
                    </h2>
                    <div className={styles.logo_wrapper}>
                        <div className={styles.logo_container}>
                            <img src={require('../assets/marcas/Alcon_logo.svg').default} alt="Alcon" className={styles.brand_logo}></img>
                        </div>
                        <div className={styles.logo_container}>
                            <img src={require('../assets/marcas/Coopervision_logo.png')} alt="Coopervision" className={styles.brand_logo}></img>
                        </div>
                        <div className={styles.logo_container}>
                            <img src={require('../assets/marcas/Bausch_and_Lomb_logo.svg').default} alt="Bausch and Lomb" className={styles.brand_logo}></img>
                        </div>
                        <div className={styles.logo_container}>
                            <img src={require('../assets/marcas/Johnson_and_Johnson_logo.svg').default} alt="Johnson and Johnson" className={styles.brand_logo}></img>
                        </div>
                    </div>
                </section> */}
            </div>
        );
    }
}
export default Protocolos;